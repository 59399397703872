import React from 'react';
import {Link} from 'react-router-dom';

import BannerLegend from '../../../assets/banner-legend-overlay.png';

export default function Banner() {
  return (
    <>
      <section className="hero hero-foo">
        <figure className="image is-overlay">
          <img alt="Banner" src={BannerLegend} />
        </figure>
      </section>
      <div className="box">
        <div className="container">
          <div className="columns is-gapless">
            <div className="column has-text-centered" style={{marginTop: 4}}>
              <p className="subtitle is-4 is-size-7-mobile">
                SELECIONE A UNIDADE E<strong> ENCONTRE SEU CURSO</strong>
              </p>
            </div>
            <div className="column cta">
              <nav className="level">
                <Link
                  className="button is-outlined is-info is-rounded level-item is-size-6-mobile"
                  to={{
                    pathname: '/cursosunidade/27/aracaju',
                    state: {
                      area: 'SENAC - Centro Form. Prof. Aracaju',
                    },
                  }}>
                  <span className="icon is-small">
                    <i className="fa fa-map-marker" />
                  </span>
                  <span>ARACAJU</span>
                </Link>
                <Link
                  className="button is-outlined is-info is-rounded level-item is-size-6-mobile"
                  to={{
                    pathname: '/cursosunidade/28/hotelaria',
                    state: {
                      area: 'SENAC - Empresa Pedagóg. de Hotelaria',
                    },
                  }}>
                  <span className="icon is-small">
                    <i className="fa fa-map-marker" />
                  </span>
                  <span>HOTELARIA</span>
                </Link>
                <Link
                  className="button is-outlined is-info is-rounded level-item is-size-6-mobile"
                  to={{
                    pathname: '/cursosunidade/30/itabaiana',
                    state: {
                      area: 'SENAC - Centro Form. Prof. Itabaiana',
                    },
                  }}>
                  <span className="icon is-small">
                    <i className="fa fa-map-marker" />
                  </span>
                  <span>ITABAIANA</span>
                </Link>
                <Link
                  className="button is-outlined is-info is-rounded level-item is-size-6-mobile"
                  to={{
                    pathname: '/cursosunidade/32/lagarto',
                    state: {
                      area: 'SENAC - Centro Form. Prof. Lagarto',
                    },
                  }}>
                  <span className="icon is-small">
                    <i className="fa fa-map-marker" />
                  </span>
                  <span>LAGARTO</span>
                </Link>
                <Link
                  className="button is-outlined is-info is-rounded level-item is-size-6-mobile"
                  to={{
                    pathname: '/cursosunidade/31/tobias-barreto',
                    state: {
                      area: 'SENAC - Centro Form. Prof. Tobias Barreto',
                    },
                  }}>
                  <span className="icon is-small">
                    <i className="fa fa-map-marker" />
                  </span>
                  <span>TOBIAS BARRETO</span>
                </Link>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
