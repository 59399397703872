import React from 'react';

import BannerImage from '../../../assets/section.png';

export default function Section({title}) {
  return (
    <>
      <div className="card is-hidden-touch is-shadowless">
        <div className="card-image">
          <figure className="image">
            <img src={BannerImage} alt="Placeholder" />
          </figure>
          <div className="card-content is-overlay is-clipped has-text-centered">
            <p className="title">{title}</p>
          </div>
        </div>
      </div>
    </>
  );
}
