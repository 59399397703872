import React from 'react';

import BannerPromo from '../../../assets/bannerpromo.jpg';

export default function BannerCampaign() {
  return (
    <section className="hero hero-foo">
      <figure className="image is-overlay">
        <img alt="Banner" style={{height: 360}} src={BannerPromo} />
      </figure>
    </section>
  );
}
