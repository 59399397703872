import React, {useEffect, useState} from 'react';
import ReactTable from 'react-table';
import slugify from 'slugify';
import {Link} from 'react-router-dom';
import {animateScroll as scroll} from 'react-scroll';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';
import InternalLayout from '../layout/internal';
import AvailableVacancies from '../../components/AvailableVacancies';

import unities from '../../assets/db/unity.json';
import {Transition} from '../../components/Animations';

export default function CourseByUnity({match: {params}, location, history}) {
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState([]);

  const [unidade, setUnidade] = useState([]);

  function scrollToTop() {
    scroll.scrollToTop();
  }

  useEffect(() => {
    async function getToken() {
      scrollToTop();
      setLoading(true);
      setUnidade(
        Array.from(unities.data).filter(
          id => Number(id.UNIDADE_ID) === Number(params.id)
        )
      );

      try {
        const token = await localStorage.getItem('jwttoken');
        if (token !== null) {
          const config = {
            headers: {Authorization: `bearer ${JSON.parse(token)}`},
          };

          const response = await api.get(
            `/unidadescomturmasativas/${params.id}`,
            config
          );

          setList(response.data.data.data);

          setLoading(false);

          scrollToTop();
        } else {
          history.push(`/redirect?url=${window.location.pathname}`);
        }
      } catch (error) {
        setLoading(false);
        history.push(`/`);
      }
    }
    getToken();
  }, [history, params.id]);

  return (
    <InternalLayout
      banner
      title={unidade.map(item => item.UNIDADE_NOME).toString()}>
      <div className="section">
        <nav className="breadcrumb is-hidden-desktop" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-home" aria-hidden="true" />
                </span>
                <span>Início</span>
              </Link>
            </li>
            <li className="is-active">
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-book" aria-hidden="true" />
                </span>
                <span>Unidade</span>
              </Link>
            </li>
            <li className="is-active">
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-file" aria-hidden="true" />
                </span>
                <span>
                  {unidade.map(item => item.UNIDADE_NOME_ABREV).toString()}
                </span>
              </Link>
            </li>
          </ul>
        </nav>

        {loading ? (
          <div className="container has-text-centered">
            <section className="hero is-medium">
              <div className="hero-body">
                <Loading />
                <p className="title is-6 has-text-link">Carregando...</p>
              </div>
            </section>
          </div>
        ) : (
          <Transition>
            <div className="column is-paddingless">
              {Array.isArray(list) ? (
                <div className="card bm--card-equal-height">
                  <div className="card-content">
                    <ReactTable
                      data={Array.from(list).filter(
                        course =>
                          course.CURSO.includes('Aprendizagem') === false &&
                          course.CURSO.includes('ACESSUAS') === false &&
                          [
                            1097,
                            1271,
                            1106,
                            69,
                            1095,
                            1265,
                            1137,
                            1136,
                            1293,
                            1264,
                            1319,
                            991,
                            1002,
                            1318,
                            1003,
                            1261,
                            562,
                            1263,
                            1317,
                            1129,
                            1009,
                            1259,
                            1260,
                            963,
                            1008,
                            1262,
                            1286,
                            1288,
                            1295,
                            1296,
                            1268,
                            1269,
                            1118,
                            1140,
                            1273,
                            1275,
                            1277,
                            1031,
                            1291,
                            1280,
                            1284,
                            1278,
                            906,
                            1272,
                            1270,
                            251,
                            1290,
                            1292,
                            1294,
                            1282,
                            1267,
                            1258,
                            1270,
                            1052,
                            1032,
                            1289,
                            1285,
                            1014,
                            1029,
                          ].includes(Number(course.CURSO_ID))
                      )}
                      sorted={[
                        {
                          id: 'CURSO',
                          asc: true,
                        },
                      ]}
                      filterable
                      defaultFilterMethod={(filter, row) =>
                        String(row[filter.id]) === filter.value
                      }
                      getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                          return {
                            // eslint-disable-next-line no-unused-vars
                            onClick: e => {
                              history.push({
                                pathname: `/curso/${
                                  rowInfo.original.CURSO_ID
                                }/${slugify(rowInfo.original.CURSO, {
                                  replacement: '-', // replace spaces with replacement
                                  remove: null, // regex to remove characters
                                  lower: true, // result in lower case
                                })}`,
                                state: {
                                  area: '',
                                },
                              });
                            },
                          };
                        }
                        return {};
                      }}
                      columns={[
                        {
                          Header: 'Cód.',
                          id: 'CURSO_ID',
                          accessor: 'CURSO_ID',
                          show: false,
                        },
                        {
                          Header: 'Cód.',
                          id: 'CIDADE',
                          accessor: 'CIDADE',
                          show: false,
                        },
                        {
                          Header: 'Cursos com Turmas Ativas',
                          id: 'CURSO',
                          accessor: 'CURSO',
                          Cell: row => {
                            return (
                              <div className="field is-grouped is-grouped-multiline">
                                <div className="control">
                                  <p className="is-size-6 is-size-7-mobile is-italic has-text-weight-bold has-text-grey-dark">
                                    {`${row.row.CURSO.replace(
                                      /Aprendizagem Profissional Comercial em/i,
                                      'Aprendizagem em'
                                    )}  `}
                                  </p>
                                </div>
                                <div className="control is-hidden-touch">
                                  <span className="tag is-small is-rounded is-white">
                                    {`Cód. ${row.row.CURSO_ID} `}
                                  </span>
                                </div>
                                <div className="control is-hidden-touch">
                                  <span className="tag is-small is-rounded is-info">
                                    {`${row.row.CIDADE} `}
                                  </span>
                                </div>
                              </div>
                            );
                          },
                          filterMethod: (filter, row) =>
                            row[filter.id]
                              .toLowerCase()
                              .indexOf(filter.value.toLowerCase()) >= 0,
                          Filter: ({filter, onChange}) => (
                            <div className="field">
                              <p className="control has-icons-left">
                                <input
                                  className="input is-large"
                                  type="text"
                                  placeholder="Pesquisar"
                                  value={filter ? filter.value : ''}
                                  onChange={event =>
                                    onChange(event.target.value)
                                  }
                                />
                                <span className="icon is-small is-left">
                                  <i className="fa fa-search" />
                                </span>
                              </p>
                            </div>
                          ),
                        },
                      ]}
                      defaultPageSize={14}
                      className="-striped -highlight"
                      // Text
                      previousText="Anterior"
                      nextText="Próxima"
                      loadingText="Carregando..."
                      noDataText="Nenhum registro foi encontrado..."
                      pageText="Pag."
                      ofText="de"
                      rowsText="linhas"
                      // Accessibility Labels
                      pageJumpText="ir para pag."
                      rowsSelectorText="linhas por pág."
                    />
                  </div>
                </div>
              ) : (
                <div className="container has-text-centered">
                  <section className="hero is-medium">
                    <div className="hero-body">
                      <p className="title has-text-link is-4">
                        Não há cursos disponíveis na área selecionada.
                      </p>
                      <NotFound />
                    </div>
                  </section>
                </div>
              )}
            </div>
          </Transition>
        )}
      </div>
    </InternalLayout>
  );
}
