import React from 'react';

import {Link} from 'react-router-dom';

import slugify from 'slugify';

// import { Container } from './styles';

export default function Area({areaId, descricaoArea, image}) {
  return (
    <>
      <div className="column is-one-quarter-desktop">
        <div className="card is-shady">
          <Link
            to={{
              pathname: `/cursos/${areaId}/${slugify(descricaoArea, {
                replacement: '-', // replace spaces with replacement
                remove: null, // regex to remove characters
                lower: true, // result in lower case
              })}`,
              state: {
                area: {descricaoArea},
              },
            }}>
            <div className="card-image">
              <figure className="image">
                <img src={image} alt="Placeholder" />
              </figure>
              <div className="card-content is-overlay is-clipped">
                <span className="tag is-medium is-link is-pulled-left is-size-7-mobile">
                  <h3>{descricaoArea}</h3>
                </span>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
