import React, {useEffect, useState} from 'react';

import queryString from 'query-string';
import api from '../../services/api';

export default function Token({history, location}) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getToken() {
      setLoading(true);

      try {
        const targetUrl = queryString.parse(location.search);

        const data = {
          token: '5d014eb8f9d43a27c0d0a898400ffa9c',
        };

        const response = await api.post('/session', data);

        const {token_jwt} = response.data;

        localStorage.setItem('jwttoken', JSON.stringify(token_jwt));

        setLoading(false);

        history.push(targetUrl.url);
      } catch (error) {
        setLoading(false);

        history.push(`/`);
      }
    }
    getToken();
  }, [history, location.search]);

  return <div />;
}
