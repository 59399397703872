import React from 'react';

import LogoBeP from '../../../assets/logopeb.png';

import Cartoes from '../../../assets/cartoes.png';

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <div className="container">
          <div className="content has-text-centered">
            <p className="has-text-white ">Aceitamos cartões de crédito</p>
            <p>
              <img src={Cartoes} alt="Cartões" />
            </p>
            <p className="has-text-white is-size-7">
              Confira as bandeiras disponíveis para matrícula.
            </p>
            <hr className="has-text-info" />
            <p>
              <img
                src={LogoBeP}
                alt="Logo"
                style={{width: 135, marginLeft: 15}}
              />
            </p>
            <p className="has-text-white">
              Departamento Regional em Sergipe <br />
              Av. Ivo do Prado, 564 | São José 49.015-070 | (79) 3212-1560
            </p>
            <p className="has-text-info is-size-7">
              Os conteúdos dos Cursos/Programações podem sofrer
              alterações/atualizações.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
