import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import slugify from 'slugify';
import {animateScroll as scroll} from 'react-scroll';
import api from '../../services/api';
import Loading from '../../components/Loading';
import NotFound from '../../components/NotFound';
import InternalLayout from '../layout/internal';

import Area from '../../assets/db/area.json';
import {Transition} from '../../components/Animations';

export default function CampaignList({match: {params}, location, history}) {
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState([]);

  const [area, setArea] = useState([]);

  function scrollToTop() {
    scroll.scrollToTop();
  }

  useEffect(() => {
    async function getToken() {
      scrollToTop();

      setLoading(true);

      setArea(
        Array.from(Area.data).filter(
          id => Number(id.AREA_ID) === Number(params.id)
        )
      );

      try {
        const token = await localStorage.getItem('jwttoken');
        if (token !== null) {
          const config = {
            headers: {Authorization: `bearer ${JSON.parse(token)}`},
          };

          const response = await api.get(`/cursosareas/${params.id}`, config);

          setList(response.data.data.data);

          setLoading(false);

          scrollToTop();
        } else {
          history.push(`/redirect?url=${window.location.pathname}`);
        }
      } catch (error) {
        setLoading(false);
        history.push(`/`);
      }
    }
    getToken();
  }, [history, params.id]);

  return (
    <InternalLayout
      bannerpromo
      title={area.map(item => item.DESCRICAO_AREA).toString()}>
      <div className="section">
        <nav className="breadcrumb is-hidden-desktop" aria-label="breadcrumbs">
          <ul>
            <li>
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-home" aria-hidden="true" />
                </span>
                <span>Início</span>
              </Link>
            </li>
            <li className="is-active">
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-book" aria-hidden="true" />
                </span>
                <span>Área</span>
              </Link>
            </li>
            <li className="is-active">
              <Link to="/">
                <span className="icon is-small">
                  <i className="fa fa-file" aria-hidden="true" />
                </span>
                <span>{area.map(item => item.DESCRICAO_AREA).toString()}</span>
              </Link>
            </li>
          </ul>
        </nav>

        {loading ? (
          <div className="container has-text-centered">
            <section className="hero is-medium">
              <div className="hero-body">
                <Loading />
                <p className="title is-6 has-text-link">Carregando...</p>
              </div>
            </section>
          </div>
        ) : (
          <Transition>
            <div className="columns is-multiline">
              {Array.isArray(list) ? (
                list
                  .filter(
                    item =>
                      item.CURSO_ID === 1200 ||
                      item.CURSO_ID === 1197 ||
                      item.CURSO_ID === 1199 ||
                      item.CURSO_ID === 1198 ||
                      item.CURSO_ID === 1237 ||
                      item.CURSO_ID === 1236 ||
                      item.CURSO_ID === 1235 ||
                      item.CURSO_ID === 1234
                  )
                  .map(course => (
                    <div className="column is-one-quarter-desktop is-half-tablet">
                      <div className="card bm--card-equal-height">
                        <div className="card-content">
                          <div className="tags is-small">
                            <span className="tag is-warning">
                              {course.DN_TIPO_CURSO}
                            </span>
                          </div>
                          <p className="title is-4">
                            {course.CURSO_NOME.replace(
                              /Aprendizagem Profissional Comercial em/i,
                              'Aprendizagem em'
                            )}
                          </p>
                          <p className="subtitle is-size-7">
                            {course.TIPO_CURSO}
                          </p>
                        </div>
                        <footer className="card-footer">
                          <Link
                            className="card-footer-item"
                            to={{
                              pathname: `/curso/${course.CURSO_ID}/${slugify(
                                course.CURSO_NOME,
                                {
                                  replacement: '-', // replace spaces with replacement
                                  remove: null, // regex to remove characters
                                  lower: true, // result in lower case
                                }
                              )}}`,
                              state: {
                                area: course.CURSO_NOME,
                              },
                            }}>
                            {course.QTD_UNIDADES === 0 ? (
                              <span className="tag is-small is-rounded is-light">
                                Curso indisponível
                              </span>
                            ) : (
                              <p className="buttons">
                                <Link
                                  to={`/curso/${course.CURSO_ID}/${slugify(
                                    course.CURSO_NOME,
                                    {
                                      replacement: '-', // replace spaces with replacement
                                      remove: null, // regex to remove characters
                                      lower: true, // result in lower case
                                    }
                                  )}`}
                                  className="button is-small is-rounded is-link">
                                  <span className="icon">
                                    <i className="fa fa-map-marker" />
                                  </span>
                                  <span>
                                    Disponível em {course.QTD_UNIDADES}{' '}
                                    unidade(s)
                                  </span>
                                </Link>
                              </p>
                            )}
                          </Link>
                        </footer>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="container has-text-centered">
                  <section className="hero is-medium">
                    <div className="hero-body">
                      <p className="title has-text-link is-4">
                        Não há cursos disponíveis na área selecionada.
                      </p>
                      <NotFound />
                    </div>
                  </section>
                </div>
              )}
            </div>
          </Transition>
        )}
      </div>
    </InternalLayout>
  );
}
