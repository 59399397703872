import styled, {keyframes} from 'styled-components';
import {fadeIn, shake, swing} from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

const Transition = styled.div`
  animation: 1s ${fadeInAnimation};
`;

const shakeAnimation = keyframes`${shake}`;

const Shake = styled.div`
  animation: 1s ${shakeAnimation};
`;

const swingAnimation = keyframes`${swing}`;

const Swing = styled.div`
  animation: 1s ${swingAnimation};
`;

export {Transition, Shake, Swing};
