import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper} from './styles';

import Footer from '../footer';
import Nav from '../nav';

import Banner from '../banner';

export default function DefaultLayout({children}) {
  return (
    <Wrapper>
      <Nav />
      <Banner />
      <section className="container">{children}</section>
      <Footer />
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
