import React from 'react';

import BannerCarretaImg from '../../../assets/bannercarreta.jpg';

export default function BannerCarreta() {
  return (
    <section className="hero hero-foo">
      <figure className="image is-overlay">
        <img alt="Banner" style={{height: 390}} src={BannerCarretaImg} />
      </figure>
    </section>
  );
}
