import React from 'react';
import {Switch} from 'react-router-dom';
import Route from './Route';

// Pages
import Main from '../pages/Main';
import CourseDetail from '../pages/CourseDetail';
import CourseList from '../pages/CourseList';
import Dashboard from '../pages/Dashboard';
import CourseByUnity from '../pages/CourseByUnity';
import Token from '../pages/Token';
import CampaignList from '../pages/CampaignList';
import GastronomyList from '../pages/GastronomyList';

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={Main} />
      <Route path="/cursos/:id/:area" component={CourseList} />
      <Route path="/campaign/:id/:area" component={CampaignList} />
      <Route path="/gastronomy/:id/:area" component={GastronomyList} />
      <Route path="/curso/:id/:area" component={CourseDetail} />
      <Route path="/cursosunidade/:id/:unidade" component={CourseByUnity} />
      <Route path="/dashboard" exact component={Dashboard} isPrivate />
      <Route path="/redirect" component={Token} />
      <Route path="/" component={Main} />
    </Switch>
  );
}
