import React, {useEffect, useState} from 'react';
import {animateScroll as scroll} from 'react-scroll';
import ReactGA from 'react-ga';
import Area from './Area';
import api from '../../services/api';
import DefaultLayout from '../layout/default';
import {Transition} from '../../components/Animations';

import informaticaImg from '../../assets/informatica.jpg';

import saudeImg from '../../assets/saude.jpg';

import gastronomiaImg from '../../assets/gastronomia.jpg';

import modaImg from '../../assets/moda.jpeg';

import belezaImg from '../../assets/beleza.jpg';

import comunicacaoImg from '../../assets/comunicacao.jpg';

import idiomasImg from '../../assets/idiomas.jpg';

import gestaoNegociosImg from '../../assets/gestao-negocios.jpg';

import comercioImg from '../../assets/comercio.jpg';

import infraestruturaImg from '../../assets/infraestrutura.jpg';

import segurancaImg from '../../assets/seguranca.jpg';

import conservacaoImg from '../../assets/conservacao.jpg';

import transporteImg from '../../assets/transporte.jpg';

import hotelariaImg from '../../assets/hotelaria.jpg';

// import { Container } from './styles';

export default function Main() {
  const [loading, setLoading] = useState(false);

  function scrollToTop() {
    scroll.scrollToTop();
  }

  useEffect(() => {
    async function getToken() {
      setLoading(true);

      try {
        const data = {
          token: '5d014eb8f9d43a27c0d0a898400ffa9c',
        };

        const response = await api.post('/session', data);

        const {token_jwt} = response.data;

        localStorage.setItem('jwttoken', JSON.stringify(token_jwt));

        setLoading(false);

        scrollToTop();
      } catch (error) {
        setLoading(false);
      }
    }
    getToken();
  }, []);

  return (
    <DefaultLayout>
      <Transition>
        <div className="intro column is-12">
          <p className="title is-4 is-hidden-touch">
            {/* Desktop */}
            <strong>MATRÍCULAS ONLINE</strong>
          </p>
          <div className="columns is-multiline is-hidden-touch">
            <Area
              areaId="14"
              descricaoArea="Informática"
              image={informaticaImg}
            />
            <Area areaId="11" descricaoArea="Saúde" image={saudeImg} />
            <Area
              areaId="263"
              descricaoArea="Gastronomia"
              image={gastronomiaImg}
            />
            <Area areaId="140" descricaoArea="Moda" image={modaImg} />
            <Area areaId="142" descricaoArea="Beleza" image={belezaImg} />
            <Area
              areaId="4"
              descricaoArea="Comunicação"
              image={comunicacaoImg}
            />
            <Area areaId="241" descricaoArea="Idiomas" image={idiomasImg} />
            <Area
              areaId="1"
              descricaoArea="Gestão e Negócios"
              image={gestaoNegociosImg}
            />
            <Area areaId="2" descricaoArea="Comércio" image={comercioImg} />
            <Area
              areaId="265"
              descricaoArea="Infraestrutura"
              image={infraestruturaImg}
            />
            <Area areaId="220" descricaoArea="Segurança" image={segurancaImg} />
            <Area
              areaId="264"
              descricaoArea="Conservaçäo e Zeladoria"
              image={conservacaoImg}
            />
            <Area
              areaId="267"
              descricaoArea="Transporte e Armazenagem"
              image={transporteImg}
            />
            <Area
              areaId="262"
              descricaoArea="Hospedagem"
              image={hotelariaImg}
            />
          </div>
          {/* Mobile */}
          <p className="title is-size-5-mobile is-hidden-desktop">
            <strong>ÁREAS DE INTERESSE</strong>
          </p>

          <div className="columns is-mobile is-hidden-desktop">
            <Area
              areaId="14"
              descricaoArea="Informática"
              image={informaticaImg}
            />
            <Area areaId="11" descricaoArea="Saúde" image={saudeImg} />
          </div>

          <div className="columns is-mobile is-hidden-desktop">
            <Area
              areaId="263"
              descricaoArea="Gastronomia"
              image={gastronomiaImg}
            />
            <Area areaId="140" descricaoArea="Moda" image={modaImg} />
          </div>

          <div className="columns is-mobile is-hidden-desktop">
            <Area areaId="142" descricaoArea="Beleza" image={belezaImg} />
            <Area
              areaId="4"
              descricaoArea="Comunicação"
              image={comunicacaoImg}
            />
          </div>

          <div className="columns is-mobile is-hidden-desktop">
            <Area areaId="241" descricaoArea="Idiomas" image={idiomasImg} />
            <Area
              areaId="1"
              descricaoArea="Gestão e Negócios"
              image={gestaoNegociosImg}
            />
          </div>
          <div className="columns is-mobile is-hidden-desktop">
            <Area areaId="2" descricaoArea="Comércio" image={comercioImg} />
            <Area
              areaId="265"
              descricaoArea="Infraestrutura"
              image={infraestruturaImg}
            />
          </div>

          <div className="columns is-mobile is-hidden-desktop">
            <Area areaId="220" descricaoArea="Segurança" image={segurancaImg} />
            <Area
              areaId="264"
              descricaoArea="Asseio e Conservação"
              image={conservacaoImg}
            />
          </div>
          <div className="columns is-mobile is-hidden-desktop">
            <Area
              areaId="267"
              descricaoArea="Transporte e Armazenagem"
              image={transporteImg}
            />
            <Area
              areaId="262"
              descricaoArea="Hospedagem"
              image={hotelariaImg}
            />
          </div>
        </div>
      </Transition>
    </DefaultLayout>
  );
}
