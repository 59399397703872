import React from 'react';

import PropTypes from 'prop-types';

import {Wrapper} from './styles';

import Footer from '../footer';
import Nav from '../nav';

import Section from '../section';
import BannerCampaign from '../bannercampign';
import BannerCarreta from '../bannercarreta';

export default function InternalLayout({
  children,
  title,
  banner,
  bannerpromo,
  bannercarreta,
}) {
  return (
    <Wrapper>
      <Nav />
      {banner && <Section title={title} />}

      {bannerpromo && <BannerCampaign />}

      {bannercarreta && <BannerCarreta />}

      <div className="container">{children}</div>

      <Footer />
    </Wrapper>
  );
}

InternalLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
