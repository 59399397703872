import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../assets/animations/notfound.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,

  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const NotFound = () => (
  <>
    <Lottie options={defaultOptions} height={150} width={150} />
  </>
);

export default NotFound;
