import React from 'react';
import Lottie from 'react-lottie';

import * as animationData from '../assets/animations/send-message-done.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData.default,

  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const SendingMail = () => (
  <>
    <Lottie options={defaultOptions} height={100} width={100} />
  </>
);

export default SendingMail;
