import React, {useState} from 'react';

import {Link} from 'react-router-dom';

import Logo from '../../../assets/logo.png';

export default function Nav() {
  const [showMenu, setShowMenu] = useState(false);

  function toggleMenu() {
    setShowMenu(!showMenu);
  }

  const burgerActive = showMenu ? 'is-active' : '';

  return (
    <>
      <nav className="navbar">
        <div className="container">
          <div className="navbar-brand">
            <div>
              <img style={{height: 85}} src={Logo} alt="Logo" />
            </div>
            <div
              className={`navbar-burger burger ${burgerActive}`}
              onClick={toggleMenu}
              data-target="navbarExampleTransparentExample">
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navbarExampleTransparentExample"
            className={`navbar-menu ${burgerActive}`}>
            <div className="navbar-start" />

            <div className="navbar-end">
              <Link className="navbar-item" to="/" onClick={toggleMenu}>
                Início
              </Link>
              <div className="navbar-item has-dropdown is-hoverable">
                <Link
                  className="navbar-link"
                  to="/dashboard"
                  onClick={toggleMenu}>
                  Unidades
                </Link>
                <div className="navbar-dropdown is-boxed">
                  <Link
                    className="navbar-item"
                    to="/cursosunidade/27/aracaju"
                    onClick={toggleMenu}>
                    CFP Aracaju
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/cursosunidade/28/hotelaria"
                    onClick={toggleMenu}>
                    Empresa Pedagóg. de Hotelaria
                  </Link>
                  <hr className="navbar-divider" />
                  <Link
                    className="navbar-item"
                    to="/cursosunidade/30/itabaiana"
                    onClick={toggleMenu}>
                    CFP Itabaiana
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/cursosunidade/32/lagarto"
                    onClick={toggleMenu}>
                    CFP Lagarto
                  </Link>
                  <Link
                    className="navbar-item"
                    to="/cursosunidade/31/tobias-barreto"
                    onClick={toggleMenu}>
                    CFP Tobias Barreto
                  </Link>
                </div>
              </div>

              <a className="navbar-item" href="http://se.senac.br">
                Portal Senac Sergipe
              </a>

              <a
                className="navbar-item"
                href="http://www.se.senac.br/banco-de-oportunidades">
                Banco de Oportunidades
              </a>

              <div className="navbar-item">
                <div className="field is-grouped">
                  <span className="navbar-item">
                    <a
                      className="button is-warning"
                      href="https://www.instagram.com/senacse/">
                      <span className="icon">
                        <i className="fa fa-instagram" />
                      </span>
                    </a>
                    <a
                      className="button is-info"
                      href="https://www.facebook.com/SenacSE">
                      <span className="icon">
                        <i className="fa fa-facebook" />
                      </span>
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
