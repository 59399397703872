import React, {useEffect} from 'react';

import './config/ReactotronConfig';

import {Router} from 'react-router-dom';

import ReactGA from 'react-ga';
import history from './services/history';

import Routes from './routes';

import './styles/App.scss';

ReactGA.initialize('UA-174598251-1');

function App() {
  useEffect(() => {
    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
