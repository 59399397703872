import React, {useEffect, useState} from 'react';

import {Form} from '@unform/web';

import {format, parseISO} from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {animateScroll as scroll, scroller, Link} from 'react-scroll';
import * as Yup from 'yup';
import Input from '../../components/Input';
import api from '../../services/api';
import Loading from '../../components/Loading';
import SendingMail from '../../components/SendingMail';

import NotFound from '../../components/NotFound';
import InternalLayout from '../layout/internal';

import unities from '../../assets/db/unity.json';
import {Transition} from '../../components/Animations';
import apicatalogo from '../../services/apicatalogo';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Email inválido')
    .required('Campo E-mail é obrigatório'),
  name: Yup.string().required('Campo Nome é obrigatório'),
  telephone: Yup.string().required('Campo Telefone é obrigatório'),
});

export default function CourseDetail({match: {params}, location, history}) {
  const [sending, setSending] = useState(false);

  const [done, setDone] = useState(false);

  const [loading, setLoading] = useState(false);

  const [toggleAccordionOb, setToggleAccordionOb] = useState(true);
  const [toggleAccordionRa, setToggleAccordionRa] = useState(false);
  const [toggleAccordionCh, setToggleAccordionCh] = useState(false);

  const [course, setCourse] = useState([]);

  const [unidade] = useState(Array.from(unities.data));

  function scrollToTop() {
    scroll.scrollToTop();
  }

  async function handleSubmit(data) {
    console.log(data);
    setSending(true);
    scrollToTop();

    data.courseuid = params.id;
    data.coursename = params.area;

    try {
      const response = await apicatalogo.post(`/intentions/`, data);

      if (response.status === 200) {
        setSending(false);
        setDone(true);
      } else {
        setSending(false);
        setDone(false);
      }
    } catch (error) {
      setSending(false);
    }
  }

  useEffect(() => {
    async function getToken() {
      scrollToTop();

      setLoading(true);

      try {
        const token = await localStorage.getItem('jwttoken');
        if (token !== null) {
          const config = {
            headers: {Authorization: `bearer ${JSON.parse(token)}`},
          };

          const response = await api.get(
            `/cursosprogramacoes/${params.id}`,
            config
          );

          setCourse([response.data.data.data[0]]);

          setLoading(false);

          scrollToTop();
        } else {
          history.push(`/redirect?url=${window.location.pathname}`);
        }
      } catch (error) {
        setLoading(false);
        history.push(`/`);
      }
    }
    getToken();
  }, [history, params.id]);

  return (
    <InternalLayout>
      <div className="section">
        <p className="title article-title is-hidden-desktop" />
        {loading ? (
          <div className="container has-text-centered">
            <section className="hero is-medium">
              <div className="hero-body">
                <Loading />
                <p className="title is-6 has-text-link">Carregando...</p>
              </div>
            </section>
          </div>
        ) : (
          <Transition>
            <div className="columns">
              {Array.isArray(course) ? (
                course.map(course => (
                  <>
                    <div className="column">
                      <div className="card article">
                        <div className="card-content">
                          <div className="media">
                            <div className="media-center" />
                            <div className="media-content has-text-centered">
                              <p className="title is-size-4-mobile article-title">
                                <strong>{course.CURSO_NOME}</strong>
                              </p>
                              <hr className="separator" />
                            </div>
                          </div>
                          {course.TURMAS.filter(
                            item =>
                              item.SITUACAO_TURMA === 'PREVISTA' &&
                              Number(item.VALOR_PAGAR) > 0
                            // &&
                            // Number(item.UNIDADE_ID) !== 29
                          ).length === 0 ? (
                            <>
                              <p className="has-text-centered">
                                <p className="tag is-rounded is-size-7-mobile is-medium is-warning">
                                  Não há programação prevista para este curso
                                </p>
                              </p>
                              <br />
                              {done ? (
                                <div className="notification is-success">
                                  <strong>
                                    Recebemos sua solicitação. Informaremos
                                    quando houver novas turmas!
                                  </strong>
                                </div>
                              ) : null}
                              {sending ? (
                                <div className="container has-text-centered">
                                  <section className="hero is-medium">
                                    <div className="hero-body">
                                      <SendingMail />
                                    </div>
                                  </section>
                                </div>
                              ) : (
                                <Form schema={schema} onSubmit={handleSubmit}>
                                  <div className="box has-background-white-bis">
                                    <p className="title is-5 has-text-black-bis">
                                      Gostaria de receber novidades sobre este
                                      curso? Preencha o formulário abaixo.
                                    </p>
                                    <div className="field">
                                      <label className="label is-small">
                                        Nome
                                      </label>
                                      <div className="control">
                                        <Input
                                          name="name"
                                          className="input is-warning"
                                          type="text"
                                          placeholder="Seu nome"
                                        />
                                      </div>
                                    </div>

                                    <div className="field">
                                      <label className="label is-small">
                                        Email
                                      </label>
                                      <div className="control">
                                        <Input
                                          name="email"
                                          className="input is-warning"
                                          type="email"
                                          placeholder="Seu email"
                                        />
                                      </div>
                                    </div>

                                    <div className="field">
                                      <label className="label is-small">
                                        Telefone
                                      </label>
                                      <div className="control">
                                        <Input
                                          name="telephone"
                                          className="input is-warning"
                                          type="text"
                                          placeholder="Seu Telefone"
                                        />
                                      </div>
                                    </div>

                                    <div className="field is-grouped is-grouped-centered">
                                      <div className="control">
                                        <button
                                          type="submit"
                                          className="button is-link is-size-7-mobile">
                                          <span className="icon">
                                            <i className="fa fa-check" />
                                          </span>
                                          <span>
                                            Avise-me quando estiver disponível
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </Form>
                              )}
                            </>
                          ) : (
                            <>
                              <p className="has-text-centered">
                                <Link to="anchor" spy smooth duration={500}>
                                  <span className="tag is-rounded is-size-7-mobile is-medium is-success">
                                    {course.QTD_UNIDADES === 0
                                      ? `CURSO DISPONÍVEL EM 1
                                  UNIDADE(S))`
                                      : `CURSO DISPONÍVEL EM: ${course.QTD_UNIDADES}
                                  UNIDADE(S)`}
                                  </span>
                                </Link>
                              </p>
                              <br />
                            </>
                          )}
                          <div className="content article-body">
                            <div className="accordion">
                              {course.INFORMATIVO.filter(
                                area => area.TITULO_DESCRICAO === 'Objetivo'
                              ).map(item => (
                                <>
                                  <div className="accordion-item">
                                    <a
                                      onClick={() =>
                                        setToggleAccordionOb(!toggleAccordionOb)
                                      }
                                      className={`${
                                        toggleAccordionOb ? 'active' : ''
                                      }`}>
                                      Objetivo
                                    </a>
                                    <div
                                      className={`content ${
                                        toggleAccordionOb ? 'active' : ''
                                      }`}>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.INFORMATIVO_DESCRICAO,
                                        }}
                                      />
                                    </div>
                                  </div>
                                </>
                              ))}

                              {course.INFORMATIVO.filter(
                                area =>
                                  area.TITULO_DESCRICAO ===
                                  'Requisitos de Acesso'
                              ).map(item => (
                                <>
                                  <a
                                    onClick={() =>
                                      setToggleAccordionRa(!toggleAccordionRa)
                                    }
                                    className={`${
                                      toggleAccordionRa ? 'active' : ''
                                    }`}>
                                    Requisitos de Acesso
                                  </a>
                                  <div
                                    className={`content ${
                                      toggleAccordionRa ? 'active' : ''
                                    }`}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.INFORMATIVO_DESCRICAO,
                                      }}
                                    />
                                  </div>
                                </>
                              ))}

                              {course.INFORMATIVO.filter(
                                area =>
                                  area.TITULO_DESCRICAO === 'Carga Horária'
                              ).map(item => (
                                <>
                                  <a
                                    onClick={() =>
                                      setToggleAccordionCh(!toggleAccordionCh)
                                    }
                                    className={`${
                                      toggleAccordionCh ? 'active' : ''
                                    }`}>
                                    Carga Horária
                                  </a>

                                  <div
                                    className={`content ${
                                      toggleAccordionCh ? 'active' : ''
                                    }`}>
                                    <p
                                      dangerouslySetInnerHTML={{
                                        __html: item.INFORMATIVO_DESCRICAO,
                                      }}
                                    />
                                  </div>
                                </>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="column is-5" id="anchor">
                      <div className="card article course-list-bg">
                        <div className="card-content">
                          <>
                            <div className="notification is-warning">
                              A matrícula on-line só poderá ser realizadas até
                              01 (um) dia antes da data de início da Turma. Para
                              realizar a matrícula na data de início favor
                              comparecer a Central de Atendimento do Senac
                              Sergipe.
                            </div>
                            <p className="title is-4 has-text-white	has-text-centered">
                              <strong>Agenda de Cursos</strong>
                            </p>
                          </>
                          {unidade.map(unidade => (
                            <div className="box">
                              <table className="table is-fullwidth">
                                <tbody>
                                  <tr>
                                    <th className="is-size-5 has-text-centered">
                                      {`${unidade.UNIDADE_NOME}`}
                                    </th>
                                  </tr>
                                  {course.TURMAS.filter(
                                    item =>
                                      item.SITUACAO_TURMA === 'PREVISTA' &&
                                      item.UNIDADE_ID === unidade.UNIDADE_ID &&
                                      Number(item.VALOR_PAGAR) > 0
                                    // &&
                                    // Number(item.UNIDADE_ID) !== 29
                                  ).map(turma => (
                                    <>
                                      <tr className="is-size-6 is-size-7-mobile">
                                        <td className="has-text-centered">
                                          <p className="has-text-link has-text-weight-bold">
                                            {`${format(
                                              parseISO(`${turma.DATA_INICIO}`),
                                              'dd/MM/yyyy',
                                              {
                                                locale: ptBR,
                                              }
                                            )} à ${format(
                                              parseISO(turma.DATA_TERMINO),
                                              'dd/MM/yyyy',
                                              {
                                                locale: ptBR,
                                              }
                                            )} | ${turma.HORARIO_INICIAL} à ${
                                              turma.HORARIO_TERMINO
                                            } | ${turma.FREQUENCIA}`}
                                          </p>
                                          <div className="tags are-small">
                                            {turma.PLANOS_PAGAMENTOS.filter(
                                              item =>
                                                item.TIPO_PLANO ===
                                                'Boleto Bancário'
                                            ).map(plano =>
                                              plano.QUANTIDADE_PARCELAS === 0 ||
                                              plano.QUANTIDADE_PARCELAS ===
                                                1 ? (
                                                <span className="tag is-rounded is-warning">
                                                  {`Investimento ${new Intl.NumberFormat(
                                                    'pt-BR',
                                                    {
                                                      style: 'currency',
                                                      currency: 'BRL',
                                                    }
                                                  ).format(turma.VALOR_PAGAR)}`}
                                                </span>
                                              ) : (
                                                <span className="tag is-rounded is-warning">{`Investimento ${new Intl.NumberFormat(
                                                  'pt-BR',
                                                  {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                  }
                                                ).format(
                                                  turma.VALOR_PAGAR
                                                )} em ${Number(
                                                  plano.QUANTIDADE_PARCELAS
                                                )}x ${new Intl.NumberFormat(
                                                  'pt-BR',
                                                  {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                  }
                                                ).format(
                                                  turma.VALOR_PAGAR /
                                                    plano.QUANTIDADE_PARCELAS
                                                )}`}</span>
                                              )
                                            )}

                                            {turma.PLANOS_PAGAMENTOS.filter(
                                              item =>
                                                item.AMBIENTE ===
                                                'Matrícula On-line'
                                            ).map(plano =>
                                              plano.QUANTIDADE_PARCELAS === 0 ||
                                              plano.QUANTIDADE_PARCELAS ===
                                                1 ? (
                                                <span className="tag is-rounded is-warning">
                                                  {`Investimento ${new Intl.NumberFormat(
                                                    'pt-BR',
                                                    {
                                                      style: 'currency',
                                                      currency: 'BRL',
                                                    }
                                                  ).format(turma.VALOR_PAGAR)}`}
                                                </span>
                                              ) : (
                                                <span className="tag is-rounded is-warning">{`Investimento ${new Intl.NumberFormat(
                                                  'pt-BR',
                                                  {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                  }
                                                ).format(
                                                  turma.VALOR_PAGAR
                                                )} em ${Number(
                                                  plano.QUANTIDADE_PARCELAS
                                                )}x ${new Intl.NumberFormat(
                                                  'pt-BR',
                                                  {
                                                    style: 'currency',
                                                    currency: 'BRL',
                                                  }
                                                ).format(
                                                  turma.VALOR_PAGAR /
                                                    plano.QUANTIDADE_PARCELAS
                                                )}`}</span>
                                              )
                                            )}
                                            {turma.VAGAS_DISPONIVEIS > 0 &&
                                            turma.DISPONIVEL_MATRICULA_ONLINE ===
                                              'S' ? (
                                              <>
                                                <span className="tag is-rounded is-success">{`${turma.VAGAS_DISPONIVEIS} vagas disponíveis`}</span>
                                                <a
                                                  href={`${turma.LINK_MATRICULA_ONLINE}`}
                                                  className="button is-small is-success">
                                                  <span className="icon">
                                                    <i className="fa fa-arrow-circle-right" />
                                                  </span>
                                                  <span>MATRICULE-SE</span>
                                                </a>
                                              </>
                                            ) : (
                                              <span className="tag is-rounded is-danger">
                                                Vagas Esgotadas
                                              </span>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                                  {course.TURMAS.filter(
                                    item =>
                                      item.SITUACAO_TURMA === 'PREVISTA' &&
                                      item.UNIDADE_ID === unidade.UNIDADE_ID &&
                                      Number(item.VALOR_PAGAR) > 0
                                    // &&
                                    // Number(item.UNIDADE_ID) !== 29
                                  ).length === 0 && (
                                    <tr className="is-size-7 ">
                                      <td>
                                        <span className="has-text-centered">
                                          <p className="has-text-grey has-text-weight-bold">
                                            Não há programação prevista nesta
                                            Unidade.
                                          </p>
                                        </span>
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ))}

                          {course.TURMAS.filter(
                            item => item.SITUACAO_TURMA === 'ANDAMENTO'
                          ).length !== 0 && (
                            <p className="title is-4 has-text-white	has-text-centered">
                              <strong>Turmas em Andamento</strong>
                            </p>
                          )}

                          {course.TURMAS.filter(
                            item => item.SITUACAO_TURMA === 'ANDAMENTO'
                          ).map(turma => (
                            <div className="box has-background-white-ter">
                              <table className="table is-fullwidth has-background-white-ter">
                                <tbody>
                                  <tr>
                                    <th className="is-size-5 has-text-centered">
                                      {`${turma.UNIDADE_NOME}`}
                                    </th>
                                  </tr>
                                  <tr className="is-size-6">
                                    <td>
                                      <p className="has-text-centered">
                                        <p className="has-text-link has-text-weight-bold">
                                          {`${format(
                                            parseISO(`${turma.DATA_INICIO}`),
                                            'dd/MM/yyyy',
                                            {
                                              locale: ptBR,
                                            }
                                          )} à ${format(
                                            parseISO(turma.DATA_TERMINO),
                                            'dd/MM/yyyy',
                                            {
                                              locale: ptBR,
                                            }
                                          )} | ${turma.HORARIO_INICIAL} à ${
                                            turma.HORARIO_TERMINO
                                          } | ${turma.FREQUENCIA}`}
                                        </p>
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div className="container has-text-centered">
                  <section className="hero is-medium">
                    <div className="hero-body">
                      <p className="title has-text-link is-4">
                        Curso não encontrado.
                      </p>
                      <NotFound />
                    </div>
                  </section>
                </div>
              )}
            </div>
          </Transition>
        )}
      </div>
    </InternalLayout>
  );
}
